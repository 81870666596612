import React, { FC, PropsWithChildren, useState, useRef, useEffect } from 'react';

import { DropdownName } from './models/services/dropdown-name';
import { OpenParams } from './models/services/dropdown-interface';
import { Model } from './index.model';

import { DropdownContext } from './views/services/dropdown-context';

import { MenuItemActions } from './views/interfaces/menu-item-actions';
import { Content, Root, Menu } from './index.styled';

const Dropdown: FC<PropsWithChildren> = ({children}) => {

  const menu = useRef<HTMLDivElement>(null);
  const [model, setModel] = useState(new Model());

  function toggle(name: DropdownName, params: OpenParams) {
    setModel(model.showing ? model.close() : model.open(name, params));
  }

  function close() {
    if (!model.showing) return;
    setModel(model.close());
  }

  function watchClick(e: Event) {
    if (!menu.current) return;
    if (menu.current.contains(e.target as Node)) return;
    if (!model.target) return;
    if (model.target.contains(e.target as Node)) return;
    setModel(model.close());
  }

  function watchShowing() {
    if (!model.showing) return;
    window.addEventListener('click', watchClick);
    return () => window.removeEventListener('click', watchClick);
  }

  useEffect(watchShowing, [model.showing]);

  return (
    <DropdownContext.Provider value={{ toggle, close }}>
      <Content>
        {children}
      </Content>
      <Root>
        <Menu ref={menu} point={model.point} className={model.showing ? 'showing' : ''}>
          {(() => {
            switch (model.name) {
              case 'menu-item-actions': return <MenuItemActions {...model.props} />;
            }
          })()}
        </Menu>
      </Root>
    </DropdownContext.Provider>
  );

};

export { Dropdown };
export { useDropdown } from './views/services/use-dropdown';
export * from './models/entities/props';