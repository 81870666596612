import styled from 'styled-components';

import { baseFontFamily, baseFontSize, logoFontSize } from 'views/services/font-scheme';
import { baseColor, primaryColor, primaryTextColor, vividPrimaryColor, accentColor, vividAccentColor } from 'views/services/color-scheme';

export const Root = styled.div`
  width: 480px;
  height: 480px;
  background-color: ${baseColor};
  border-radius: 50%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 96px;
`;

export const Top = styled.div`
  font-size: calc(${logoFontSize} * 1.5);
  display: flex;
  justify-content: center;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  & > button {
    cursor: pointer;
    font-family: ${baseFontFamily};
    font-size: ${baseFontSize};
    color: ${primaryTextColor};
    background: linear-gradient(125deg, ${primaryColor} 30%, ${accentColor});
    padding: 16px 32px;
    border-radius: 40px;
    &:hover {
      background: linear-gradient(125deg, ${vividPrimaryColor} 30%, ${vividAccentColor});
    }
  }
`;
