import { User } from './user';

interface AuthContextInterface {
  user: undefined | User;
  signInWithGoogle: () => void;
  signOut: () => void;
}

const defaultInterface = {
  user: undefined,
  signInWithGoogle: () => {},
  signOut: () => {},
};

export type { AuthContextInterface };
export { defaultInterface };