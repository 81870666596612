import React, { FC } from 'react';

import { MenuItemActionsProps } from '../../../models/entities/props';
import { useDropdown } from '../../services/use-dropdown';

import { Root } from './index.styled';

const MenuItemActions: FC<MenuItemActionsProps> = props => {

  const { onCreate, onImport, onExport, onExportCategories, onImportReport, disabledAction = {} } = props;
  const dropdown = useDropdown();

  function create() {
    if (!onCreate) return;
    if (disabledAction.onCreate) return
    onCreate();
    dropdown.close();
  }

  function doImport() {
    if (!onImport) return;
    if (disabledAction.onImport) return
    onImport();
    dropdown.close();
  }

  function doExport() {
    if (!onExport) return;
    if (disabledAction.onExport) return
    onExport();
    dropdown.close();
  }

  function exportCategories() {
    if (!onExportCategories) return;
    if (disabledAction.onExportCategories) return
    onExportCategories();
    dropdown.close();
  }

  function importReport() {
    if (!onImportReport) return;
    if (disabledAction.onImportReport) return
    onImportReport();
    dropdown.close();
  }

  return (
    <Root>
      <ol>
        {!!onCreate && <li className={disabledAction.onCreate ? 'disabled' : ''} onClick={() => create()}>Create</li>}
        {!!onImport && <li className={disabledAction.onImport ? 'disabled' : ''} onClick={() => doImport()}>Import</li>}
        {!!onExport && <li className={disabledAction.onExport ? 'disabled' : ''} onClick={() => doExport()}>Export</li>}
        {!!onExportCategories && <li className={disabledAction.onExportCategories ? 'disabled' : ''} onClick={() => exportCategories()}>Export Categories</li>}
        {!!onImportReport && <li className={disabledAction.onImportReport ? 'disabled' : ''} onClick={() => importReport()}>Import Report</li>}
      </ol>
    </Root>
  );

};

export { MenuItemActions };