const Config = {
  aws_cognito_region: process.env.GATSBY_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: process.env.GATSBY_COGNITO_USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
  Auth: {
    oauth: {
      domain: process.env.GATSBY_COGNITO_OAUTH_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: process.env.GATSBY_COGNITO_OAUTH_REDIRECT_URL,
      redirectSignOut: process.env.GATSBY_COGNITO_OAUTH_REDIRECT_URL,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};

export { Config };