import React, { useState, useEffect, useRef } from 'react';

import { useAuth } from 'views/services/auth';
import { UserIcon } from 'views/components/icons';
import { Root } from './index.styled';

const UserMenu = () => {

  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function close() {
    setIsOpen(false);
  }

  function watchClick(e: Event) {
    if (!button.current?.contains(e.target as Node)) close();
  }

  function watchIsOpen() {
    if (!isOpen) return;
    window.addEventListener('click', watchClick);
    return () => window.removeEventListener('click', watchClick);
  }

  useEffect(watchIsOpen, [isOpen]);

  return (
    <Root>
      <button ref={button} onClick={() => toggle()}>
        <UserIcon />
      </button>
      {isOpen && (
        <ol>
          <li onClick={() => auth.signOut()}>Sign Out</li>
        </ol>
      )}
    </Root>
  );

};

export { UserMenu };