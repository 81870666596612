import React from 'react';

import { useHeader } from '../../services/use-header';
import { Root } from './index.styled';

const Screen = () => {

  const header = useHeader();

  return !header.ready ? <Root /> : null;

};

export { Screen };