import React, { FC, PropsWithChildren, useState, useEffect } from 'react';
import { Amplify, Auth as AmplifyAuth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';

import { User } from './models/user';

import { AuthContext } from './views/services/auth-context';
import { useAuth } from './views/services/use-auth';
import { Config } from './views/services/config';
import { Gate } from './views/components/gate';

Amplify.configure(Config);

const Auth: FC<PropsWithChildren> = ({ children }) => {

  const [user, setUser] = useState<User>();

  function signInWithGoogle() {
    AmplifyAuth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })
    .catch(error => { throw new Error(error); });
  }

  function authorize(user: CognitoUser) {
    const ok = true;
    const admin = checkAdmin(user);
    const accounting = checkAccounting(user);
    setUser(new User({ ok, admin, accounting }));
  }

  function prohibit() {
    setUser(new User());
  }

  function signOut() {
    prohibit();
    AmplifyAuth.signOut()
    .catch(error => { throw new Error(error); });
  }

  function checkAdmin(user: CognitoUser): boolean {
    return user.getSignInUserSession()?.getIdToken().payload['cognito:groups'].includes('Admin');
  }

  function checkAccounting(user: CognitoUser): boolean {
    return user.getSignInUserSession()?.getIdToken().payload['cognito:groups'].includes('Accounting');
  }

  function checkBlackList(user: CognitoUser): boolean {
    return user.getSignInUserSession()?.getIdToken().payload['cognito:groups'].includes('BlackList');
  }

  function init() {
    AmplifyAuth.currentAuthenticatedUser()
    .then(user => checkBlackList(user) ? signOut() : authorize(user))
    .catch(() => prohibit());
  }

  useEffect(init, []);

  return (
    <AuthContext.Provider value={{ user, signInWithGoogle, signOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export { Auth, useAuth, Gate };