type DataToRender = {
  border?: boolean;
};

interface HeaderInterface {
  ready: boolean;
  render: (data: DataToRender) => void;
}

const DefaultInterface = {
  ready: false,
  render: () => {},
};

export type { HeaderInterface, DataToRender };
export { DefaultInterface };