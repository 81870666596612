import { DropdownName } from './dropdown-name';
import { DropdownProps } from './dropdown-props';
import { PointData } from '../entities/point';

type OpenParams = {
  target: Node;
  point: PointData;
  props?: DropdownProps;
};

interface DropdownInterface {
  toggle: (name: DropdownName, params: OpenParams) => void;
  close: () => void;
}

const DefaultInterface = {
  toggle: () => {},
  close: () => {},
};

export type { DropdownInterface, OpenParams };
export { DefaultInterface };