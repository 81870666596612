import React, { FC, PropsWithChildren, useState, useLayoutEffect, MouseEvent } from "react"

import { ModalName } from './models/services/modal-name';
import {} from './models/services/target-object';
import {} from './models/services/other-object';
import { OpenOptions } from './models/services/modal-interface';
import { ResultData } from './models/entities/result';
import { Model } from './index.model';

import { ModalContext } from './views/services/modal-context';

import { SignIn } from './views/interfaces/sign-in';

import { Foreground } from './views/components/foreground';
import { Main, Background } from './index.styled';

const Modal: FC<PropsWithChildren> = ({ children }) => {

  const [model, setModel] = useState(new Model());

  function open(name: ModalName, options: OpenOptions = {}) {
    setModel(model.open(name, options));
  }

  function close(result?: ResultData) {
    setModel(model.close(result));
  }

  function watchBackgroundClick(e: MouseEvent<HTMLDivElement>) {
    if (e.currentTarget !== e.target) return;
    closeWithBackgroundClick();
  }

  function closeWithBackgroundClick() {
    model.canClose() && close();
  }

  function setWindowScroll() {
    window.scrollTo(0, model.scroll);
  }

  function restoreWindowScroll() {
    if (model.state === 'closed') setWindowScroll();
  }

  useLayoutEffect(restoreWindowScroll, [model.state]);

  return (
    <ModalContext.Provider value={{ open, close }}>
      <Main className={model.state} scroll={model.scroll}>
        {children}
      </Main>
      <Background className={model.state} onClick={e => watchBackgroundClick(e)}>
        {model.layers.map((it, i) => (
          <Foreground key={i} layer={it} onOpen={() => setModel(model.ready())} onClose={() => setModel(model.unmount())} onEscape={close} onClickBackground={closeWithBackgroundClick}>
            {(() => {
              switch (it.name) {
                case 'sign-in': return <SignIn />;
              }
            })()}
          </Foreground>
        ))}
      </Background>
    </ModalContext.Provider>
  );

};

export { Modal };
export * from './views/services/use-modal';
//export * from './models/entities/target';
export { Result as ModalResult } from './models/entities/result';
