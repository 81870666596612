import styled from 'styled-components';

interface RootProps {
  order: number;
}

export const Root = styled.div<RootProps>`
  position: relative;
  grid-area: 1 / 1;
  order: ${p => p.order};
  width: fit-content;
  height: fit-content;
  &.init, &.closed {
    opacity: 0;
    transform: translateY(-64px);
  }
  &.open {
    opacity: 1;
    transform: translateY(0);
  }
  transition-duration: 300ms;
`;
