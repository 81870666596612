import React from 'react';

import { useAuth } from 'views/services/auth';
import { Logo } from 'views/components/logo';
import { Root, Top, Bottom } from './index.styled';

const SignIn = () => {

  const auth = useAuth();

  return (
    <Root>
      <Top>
        <Logo />
      </Top>
      <Bottom>
        <button onClick={() => auth.signInWithGoogle()}>Sign in with ABURI account of Google</button>
      </Bottom>
    </Root>
  );

};

export { SignIn };