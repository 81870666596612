import React, { FC, PropsWithChildren, useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';

import { DataToRender } from './models/services/header-interface';
import { Model } from './index.model';

import { HeaderContext } from './views/services/header-context';

import { useDropdown } from 'views/services/dropdown';
import { Logo } from 'views/components/logo';
import { XScrollableArea } from 'views/components/x-scrollable-area';
import { UserMenu } from 'views/components/user-menu';
import { Height, Root, Left, Home, Center, Menu, Right } from './index.styled';

const Header: FC<PropsWithChildren> = ({ children }) => {

  const dropdown = useDropdown();
  const root = useRef<HTMLDivElement>(null);
  const [model, setModel] = useState(new Model());

  function render(data: DataToRender = {}) {
    setModel(model => model.render(data));
  }

  function watchRoot() {
    if (!root.current) return;
    setModel(model => model.init());
  }

  useEffect(watchRoot, [root.current]);

  return (
    <HeaderContext.Provider value={{ ready: model.ready, render }}>
      <Root ref={root} border={model.border}>
        <Left>
          <Home><Link to="/"><Logo /></Link></Home>
        </Left>
        <Center>
          <XScrollableArea onScroll={() => dropdown.close()}>
            <Menu />
          </XScrollableArea>
        </Center>
        <Right>
          <UserMenu />
        </Right>
      </Root>
      {children}
    </HeaderContext.Provider>
  );

};

export { Header, Height as HeaderHeight };
export * from './views/services/use-header';
export * from './views/components/screen';