import { DataToRender } from './models/services/header-interface';

class Model {

  readonly ready: boolean;
  readonly border: boolean;

  constructor() {
    this.ready = false;
    this.border = false;
  }

  init(): this {
    const ready = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ready });
  }

  render(data: DataToRender = {}): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { Model };