import { hsla, mix, saturate, transparentize, lighten } from 'polished';

export const black = hsla(0, 0, 0, 1);
export const white = hsla(0, 0, 1, 1);

export const red = hsla(0, 1, 0.5, 1);
export const yellow = hsla(60, 1, 0.5, 1);
export const green = hsla(120, 1, 0.5, 1);
export const cyan = hsla(180, 1, 0.5, 1);
export const blue = hsla(240, 1, 0.5, 1);
export const magenta = hsla(300, 1, 0.5, 1);

export const baseColor = hsla(0, 0, 0.125, 1);
export const lightBaseColor = mix(0.8, baseColor, white);
export const darkBaseColor = hsla(0, 0, 0.1, 1);

export const textColor = white;
export const lightTextColor = mix(0.45, textColor, baseColor);
export const darkTextColor = mix(0.15, textColor, baseColor);

export const lineColor = mix(0.7, black, white);

export const primaryColor = hsla(355, 0.8, 0.5, 1);
export const lightPrimaryColor = mix(0.2, primaryColor, baseColor);
export const darkPrimaryColor = mix(0.6, primaryColor, baseColor);
export const vividPrimaryColor = saturate(1, darkPrimaryColor);
export const clearPrimaryColor = transparentize(0.8, primaryColor);
export const primaryTextColor = white;
export const primaryLightTextColor = mix(0.45, primaryTextColor, primaryColor);
export const primaryLineColor = mix(0.1, primaryTextColor, darkPrimaryColor);

export const secondaryColor = hsla(0, 0, 0.2, 1);
export const lightSecondaryColor = mix(0.2, secondaryColor, baseColor);
export const darkSecondaryColor = mix(0.6, secondaryColor, baseColor);
export const vividSecondaryColor = lighten(0.1, darkSecondaryColor);
export const clearSecondaryColor = transparentize(0.75, secondaryColor);
export const secondaryTextColor = white;
export const secondaryLightTextColor = mix(0.45, secondaryTextColor, secondaryColor);
export const secondaryLineColor = mix(0.2, secondaryTextColor, darkSecondaryColor);

export const accentColor = '#FF8C00';
export const lightAccentColor = mix(0.2, accentColor, baseColor);
export const darkAccentColor = mix(0.6, accentColor, baseColor);
export const vividAccentColor = lighten(0.1, darkAccentColor);
export const accentTextColor = white;

export const redColor = hsla(10, 1, 0.7, 1);
export const lightRedColor = mix(0.2, redColor, baseColor);
export const redTextColor = hsla(10, 1, 0.85, 1);
export const redLineColor = hsla(10, 1, 0.7, 1);

export const yellowColor = hsla(55, 1, 0.7, 1);
export const lightYellowColor = mix(0.2, yellowColor, baseColor);
export const yellowTextColor = hsla(55, 1, 0.85, 1);
export const yellowLineColor = hsla(55, 1, 0.7, 1);

export const greenColor = hsla(125, 1, 0.7, 1);
export const lightGreenColor = mix(0.2, greenColor, baseColor);
export const greenTextColor = hsla(125, 1, 0.85, 1);
export const greenLineColor = hsla(125, 1, 0.7, 1);

export const clearBlackColor = transparentize(0.6, black);