type Data = {
  ok?: boolean;
  admin?: boolean;
  accounting?: boolean;
};

class User {

  readonly ok: boolean;
  readonly admin: boolean;
  readonly accounting: boolean;

  constructor(data: Data = {}) {
    this.ok = data.ok ?? false;
    this.admin = data.admin ?? false;
    this.accounting = data.accounting ?? false;
  }

}

export { User };