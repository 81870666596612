const React = require('react');
const GlobalStyle = require('./src/views/services/global-style').GlobalStyle;
const Location = require('./src/views/services/location').Location;
const Auth = require('./src/views/services/auth').Auth;
const Dropdown = require('./src/views/services/dropdown').Dropdown;
const Modal = require('./src/views/services/modal').Modal;
const Gate = require('./src/views/services/auth').Gate;
const Header = require('./src/views/services/header').Header;

exports.wrapRootElement = ({ element }) => {
  return (
    <GlobalStyle>
      {element}
    </GlobalStyle>
  );
};

exports.wrapPageElement = ({ element, props }) => {
  return (
    <Location location={props.location}>
      <Auth>
        <Dropdown>
          <Modal>
            <Gate>
              <Header>
                {element}
              </Header>
            </Gate>
          </Modal>
        </Dropdown>
      </Auth>
    </Location>
  );
};
