import styled from 'styled-components';

import { logoFontSize } from 'views/services/font-scheme';
import { baseColor, lineColor } from 'views/services/color-scheme';

interface Border { border: boolean; }

export const Height = '48px';

export const Root = styled.div<Border>`
  z-index: 100;
  position: sticky;
  top: 0;
  height: ${Height};
  background-color: ${baseColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    border-bottom: 1px solid ${p => p.border ? lineColor : 'transparent'};
    pointer-events: none;
  }
`;

export const Left = styled.div`
  z-index: 102;
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Home = styled.div`
  font-size: ${logoFontSize};
`;

export const Center = styled.div`
  z-index: 101;
  flex: auto;
  height: 100%;
`;

export const Menu = styled.div`
  height: 100%;
  display: flex;
`;

export const Right = styled.div`
  z-index: 102;
  margin-right: 16px;
`;

