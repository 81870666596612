import { OpenParams } from './models/services/dropdown-interface';
import { DropdownName } from './models/services/dropdown-name';
import { DropdownProps } from './models/services/dropdown-props';
import { Point } from './models/entities/point';

class Model {

  readonly name?: DropdownName;
  readonly target?: Node;
  readonly point: Point;
  readonly props?: DropdownProps;
  readonly showing: boolean;

  constructor() {
    this.point = new Point();
    this.showing = false;
  }

  open(name: DropdownName, params: OpenParams): this {
    const point = this.point.move(params.point);
    const target = params.target;
    const props = params.props;
    const showing = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, name, target, point, props, showing });
  }

  close(): this {
    const name = undefined;
    const target = undefined;
    const point = this.point.reset();
    const props = undefined;
    const showing = false;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, name, target, point, props, showing });
  }

}

export { Model };