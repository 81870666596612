import styled from 'styled-components';

import { baseColor } from 'views/services/color-scheme';

export const Root = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: ${baseColor};
`;